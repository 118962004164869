
import {defineComponent} from 'vue'
import SquareLink from "@/components/SquareLink.vue";

export default defineComponent({
  name: "GetInvolvedHome",
  components: {SquareLink},
  methods: {
    navigate(url: string) {
      this.$router.push(url);
    },
    navigateOut(url: string) {
      window.open(url, '_blank');
    }
  }
})
