
import {defineComponent} from 'vue'

export default defineComponent({
  name: "SquareLink",
  props: {
    text: {
      type: String,
      required: true
    },
    to: {
      type: String,
      required: false
    }
  },
  methods: {
    routeTo() {
      if (this.to) {
        this.$router.push(this.to)
      }
    }
  }
})
