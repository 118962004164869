
import {defineComponent, PropType} from 'vue'
import {NNumberAnimation} from 'naive-ui';

export default defineComponent({
  name: "DataLabel",
  components: {NNumberAnimation},
  props: {
    label: {
      type: String,
      required: false
    },
    value: {
      type: Number,
      required: true
    },
    valueColor: {
      type: String as PropType<'blue' | 'green' | 'yellow' | 'pink' | 'red' | 'purple' | 'white'>,
      default: 'white'
    },
    labelColor: {
      type: String as PropType<'blue' | 'green' | 'yellow' | 'pink' | 'red' | 'purple' | 'white'>,
      default: 'white'
    }
  }
})
