import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-888ac446"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mas" }
const _hoisted_2 = {
  id: "work",
  type: "button",
  name: "Hover"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "button-container",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.routeTo && _ctx.routeTo(...args)))
  }, [
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.afterText), 1),
    _createElementVNode("button", _hoisted_2, _toDisplayString(_ctx.beforeText), 1)
  ]))
}