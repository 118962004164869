
import {defineComponent} from 'vue';
import {NNumberAnimation} from 'naive-ui';
import DataLabel from "@/components/DataLabel.vue";
import NotebookPaper from "@/components/NotebookPaper.vue";

export default defineComponent({
  name: 'ImpactView',
  components: {NotebookPaper, DataLabel, NNumberAnimation},
  data() {
    return {
      showOverviewOne: false,
      showOverviewTwo: false,
      showOverviewThree: false,
      clientWidth: window.outerWidth
    };
  },
  mounted() {
    const overviewOne = this.$refs.overviewOne as HTMLElement
    const overviewTwo = this.$refs.overviewTwo as HTMLElement

    window.addEventListener('resize', () => {
      this.clientWidth = window.outerWidth
    })

    window.addEventListener('scroll', () => {
      const overviewOneTop = overviewOne.getBoundingClientRect().top + 150
      const overviewTwoTop = overviewTwo.getBoundingClientRect().top + 150

      if (overviewOneTop < window.innerHeight) {
        this.showOverviewOne = true
      }
      if (overviewTwoTop < window.innerHeight) {
        this.showOverviewTwo = true
      }
    })
  },
  computed: {
    calcHeight() {
      if (this.clientWidth < 500) {
        return '900'
      }
      if (this.clientWidth < 1100) {
        return '700'
      } else {
        return '500'
      }
    }
  }
});
