
import {defineComponent} from 'vue'

export default defineComponent({
  name: "ButtonSquare",
  props: {
    beforeText: {
      type: String,
      required: true
    },
    afterText: {
      type: String,
      required: true
    },
    to: {
      type: String,
      required: false
    }
  },
  methods: {
    routeTo() {
      if (this.to) {
        this.$router.push(this.to)
      }
    }
  }
})
