
import {defineComponent} from 'vue'
import Socials from "@/components/Socials.vue";

export default defineComponent({
  name: "AppyFooter",
  components: {Socials},
  methods: {
    openSocial(url: string) {
      window.open(url, '_blank');
    }
  }
})
