import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router';
import HomeView from '../views/HomeView.vue';
import ImpactView from "@/views/ImpactView.vue";
import JoinView from "@/views/JoinView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/impact',
    name: 'impact',
    component: ImpactView,
  },
  {
    path: '/join',
    name: 'join',
    component: JoinView,
  },
  {
    path: '/teachers',
    name: 'teachers',
    component: () => import('../views/TeachersView.vue'),
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(() => {
  window.scrollTo(0, 0);
})

export default router;
