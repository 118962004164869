
import {defineComponent} from 'vue';
import {NDrawer, NDrawerContent} from 'naive-ui';
import Socials from "@/components/Socials.vue";
import ButtonSquare from "@/components/ButtonSquare.vue";

export default defineComponent({
  name: 'AppyNav',
  components: {ButtonSquare, Socials, NDrawer, NDrawerContent},
  props: {},
  setup() {
    return {};
  },
  data() {
    return {
      drawerOpen: false,
      fixNav: false,
    };
  },
  mounted() {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 0) {
        this.fixNav = true;
      } else {
        this.fixNav = false;
      }
    });
  }
});
