import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4b802c2e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "box" }
const _hoisted_2 = { class: "money" }
const _hoisted_3 = { class: "box-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DataLabel = _resolveComponent("DataLabel")!

  return (_openBlock(), _createElementBlock("div", {
    class: "wrapper",
    style: _normalizeStyle({
    backgroundColor: `var(--color-${_ctx.color})`,
    boxShadow: _ctx.hover ? `0 0 20px 1px var(--color-${_ctx.color})` : 'none'
  }),
    onMouseenter: _cache[0] || (_cache[0] = ($event: any) => (_ctx.hover = true)),
    onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.hover = false)),
    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.link && _ctx.link(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", {
        class: _normalizeClass(`text-${_ctx.color}`)
      }, _toDisplayString(_ctx.headerText), 3),
      _createElementVNode("div", _hoisted_2, [
        _createTextVNode(" $ "),
        _createVNode(_component_DataLabel, {
          value: _ctx.dollarAmount,
          label: ""
        }, null, 8, ["value"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]),
      (_ctx.linked)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["donate-button-slide", [`text-${_ctx.color}`, {'hovered-box': _ctx.hover}]])
          }, " DONATE ", 2))
        : _createCommentVNode("", true)
    ])
  ], 36))
}