import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ae2c9dee"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "holes hole-top"
}
const _hoisted_2 = {
  key: 1,
  class: "holes hole-middle"
}
const _hoisted_3 = {
  key: 2,
  class: "holes hole-bottom"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "paper",
    style: _normalizeStyle({
    width: `${_ctx.width}px`,
    height: `${_ctx.height}px`,
    backgroundColor: `var(--color-${_ctx.backgroundColor})`,
  })
  }, [
    _createElementVNode("div", {
      class: "lines",
      style: _normalizeStyle({backgroundImage: `repeating-linear-gradient(var(--color-${_ctx.backgroundColor}) 0px, var(--color-${_ctx.backgroundColor}) 24px, steelblue 25px)`})
    }, [
      _createElementVNode("div", {
        class: "text",
        style: _normalizeStyle({color: `var(--color-${_ctx.textColor})`})
      }, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ], 4)
    ], 4),
    (_ctx.holes)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1))
      : _createCommentVNode("", true),
    (_ctx.holes)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.holes)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3))
      : _createCommentVNode("", true)
  ], 4))
}