import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0f83cec5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "data-wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "data-label"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_number_animation = _resolveComponent("n-number-animation")!

  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createElementVNode("div", {
      class: "data-value",
      style: _normalizeStyle({color: `var(--color-${_ctx.labelColor})`})
    }, [
      _createVNode(_component_n_number_animation, {
        from: 0,
        to: _ctx.value,
        "show-separator": ""
      }, null, 8, ["to"])
    ], 4),
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true)
  ]))
}