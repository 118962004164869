
import {defineComponent} from 'vue'

export default defineComponent({
  name: "Socials",
  methods: {
    openSocial(url: string) {
      window.open(url, '_blank');
    }
  }
})
