
import {defineComponent, PropType} from 'vue'
import DataLabel from "@/components/DataLabel.vue";

export default defineComponent({
  name: "DonationTierBox",
  components: {DataLabel},
  props: {
    color: {
      type: String as PropType<'blue' | 'green' | 'yellow' | 'pink' | 'red' | 'purple' | 'white' | 'black'>,
      default: "white"
    },
    headerText: {
      type: String,
      default: "PLACEHOLDER"
    },
    dollarAmount: {
      type: Number,
      default: 0
    },
    linked: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      hover: false
    }
  },
  methods: {
    link() {
      if (this.linked) {
        window.open("https://easttnhistory.app.neoncrm.com/forms/appalachia-sessions", "_blank")
      }
    }
  }
})
