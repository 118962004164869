
import {defineComponent, PropType} from 'vue'

export default defineComponent({
  name: "NotebookPaper",
  props: {
    width: {
      type: String,
      default: '550'
    },
    height: {
      type: String,
      default: '600'
    },
    textColor: {
      type: String as PropType<'blue' | 'green' | 'yellow' | 'pink' | 'red' | 'purple' | 'white' | 'black'>,
      default: 'black'
    },
    backgroundColor: {
      type: String as PropType<'blue' | 'green' | 'yellow' | 'pink' | 'red' | 'purple' | 'white' | 'black'>,
      default: 'white'
    },
    holes: {
      type: Boolean,
      default: true
    }
  }
})
